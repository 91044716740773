import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import sumsubWebSdk from '@sumsub/websdk';
import s from "./KycBusinessStepOne.module.css";
import personalKyc from "../../../../../../assets/newImg/personalAccount/personal_kyc.png";
import MyBtn from "../../../../../../components/ui/MyBtn/MyBtn";
import classnames from "classnames";
import { motion } from 'framer-motion';
import { useAppDispatch, useAppSelector } from "../../../../../../hooks/redux";
import { deleteKycThunk, getKycListThunk } from "../../../../../../store/reducers/ActionCreators";
import {Api} from "../../../../../../api/Api";

type PropsType = {
    current: number
    setCurrent: Dispatch<SetStateAction<number>>
}

const KycBusinessStepOne = (props: PropsType) => {

    const dispatch = useAppDispatch()
    const token = useAppSelector(state => state.userReducer.user.userToken)
    const newKycList = useAppSelector(state => state.userReducer.user.kycList)

    const userData = useAppSelector(state => state.userReducer.user.userData);
    const [isLoading, setIsLoading] = useState(false);
    const [isShowSumSub, setIsShowSumSub] = useState(false);

    useEffect( () => {
        dispatch(getKycListThunk(token))
    },[dispatch, token])

    const deleteKyc = (id: any) => {
        dispatch(deleteKycThunk(token, id));
    }

    const startSumsubVerification = async () => {
        setIsLoading(true);
        setIsShowSumSub(true);
        try {

            const getStatement = async () => {
                try {
                    const res = await Api.getSumsubAccessToken(token); // Await the API call
                    return res; // Return the result
                } catch (error) {
                    return null; // Handle the error
                }
            };

// Outside the function
            const ss = await getStatement();
            const accessToken = ss.data.accessToken.token

            // Initialize the Sumsub SDK
            let snsWebSdkInstance = sumsubWebSdk
                .init(
                    accessToken,
                    // token update callback, must return Promise
                    // Access token expired
                    // get a new one and pass it to the callback to re-initiate the WebSDK
                    () => accessToken
                )
                .withConf({
                    lang: "en", //language of WebSDK texts and comments (ISO 639-1 format)
                    email: (userData.email ?? undefined),
                    phone: (userData.phoneNumber ?? undefined),
                })
                .withOptions({ addViewportTag: false, adaptIframeHeight: true })
                // see below what kind of messages WebSDK generates
                .on("idCheck.onStepCompleted", (payload) => {
                    console.log("onStepCompleted", payload);
                })
                .on("idCheck.onError", (error) => {
                    console.log("onError", error);
                })
                .build();

            console.log('sdk', snsWebSdkInstance);

            // Launch the SDK
            try {
                snsWebSdkInstance.launch('#sumsub-container .sumsub-area');

            } catch (launchError) {
                console.error('Error launching Sumsub SDK:', launchError);
            }

        } catch (error) {
            console.error('Error fetching access token or initializing Sumsub:', error);
        } finally {
            setIsLoading(false);
        }
    };


    return (
        <motion.div
            initial={{ opacity: 0, scale: 1 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
        >


            {
                !userData.biometricValidation &&
                <div className={s.verification_block}>
                    <div className={s.verification_flex}>
                        <img src={personalKyc} alt=""/>
                        <div className={s.title}>
                            Please confirm yourself
                            <p style={{fontSize:'12px',color: '#3b3b3b', maxWidth:'300px'}}>** if you verified yourself please wait till confirmed!</p>
                        </div>
                    </div>
                    <div className={s.button_wrapper}>
                        <MyBtn title={isLoading ? 'Loading...' : 'Sumsub Verification'}
                               onClick={startSumsubVerification}/>
                    </div>
                </div>
            }

            {
                newKycList?.length > 0
                    ? <></>
                    : <div className={s.verification_block}>
                        <img src={personalKyc} alt=""/>
                        <div className={s.title}>KYC for Business Account</div>
                        <div className={s.button_wrapper}>
                            <MyBtn title={"Verification"} onClick={() => props.setCurrent(1)}/>
                        </div>
                    </div>
            }

            {
                newKycList?.length > 0 &&
                <div className={s.questionnaire_list_block}>
                    <div className={s.questionnaire_title}>
                        Questionnaire List
                    </div>
                    <div className={s.questionnaire_table}>
                        <div className={s.table_header}>
                            <div className={s.header_value}>
                                Owner
                            </div>
                            <div className={s.header_value}>
                                State
                            </div>
                            {   // @ts-ignore
                                newKycList[0].status !== "Confirmed" &&
                                <div className={s.header_value}>
                                    Action
                                </div>
                            }
                        </div>
                        <div className={s.table_body}>
                            {
                                newKycList?.map((item: any, index) => {
                                    return (
                                        <div key={index} className={s.table_row}>
                                            <div className={s.row_value}>
                                                <div className={s.name}>
                                                    {item.owner}
                                                </div>
                                            </div>
                                            <div className={s.row_value}>
                                                {item.status === 'Rejected' && <div className={s.rejected}>Rejected</div>}
                                                {item.status === 'Confirmed' && <div className={s.confirmed}>Confirmed</div>}
                                                {item.status === 'Pending' && <div className={s.pending}>Pending</div>}
                                            </div>

                                            {
                                                item.status === 'Rejected'
                                                && <div className={classnames(s.row_value, s.flex, s.gap)}>
                                                    <div className={classnames(s.flex, s.action)} onClick={() => props.setCurrent(2)}>
                                                        <div className={s.edit_active}></div>
                                                        <div className={s.edit_text_active}>Edit</div>
                                                    </div>
                                                    <div className={classnames(s.flex, s.action)} onClick={() => deleteKyc(item.id)}>
                                                        <div className={s.delete_active}></div>
                                                        <div className={s.delete_text_active}>Delete</div>
                                                    </div>
                                                </div>
                                            }

                                            {
                                                item.status === 'Confirmed'
                                                && <div></div>
                                            }

                                            {
                                                item.status === 'Pending'
                                                && <div className={classnames(s.row_value, s.flex)}>
                                                    <div className={classnames(s.flex, s.action)}
                                                         onClick={() => deleteKyc(item.id)}>
                                                        <div className={s.delete_active}></div>
                                                        <div className={s.delete_text_active}>Delete</div>
                                                    </div>
                                                </div>
                                            }

                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className={s.questionnaire_table_mobile}>

                        {
                            newKycList?.map((item: any, index: number) => {
                                return (
                                    <div key={index}>
                                        <div className={s.row_mobile}>
                                            <div className={s.block}>
                                                <div className={s.label}>
                                                    OWNER
                                                </div>
                                                <div className={s.name}>
                                                    {item.owner}
                                                </div>
                                            </div>
                                            <div className={s.block}>
                                                <div className={s.label}>
                                                    State
                                                </div>
                                                <div className={s.row_value}>
                                                    {item.status === 'Rejected' &&
                                                        <div className={s.rejected}>Rejected</div>}
                                                    {item.status === 'Confirmed' &&
                                                        <div className={s.confirmed}>Confirmed</div>}
                                                    {item.status === 'Pending' &&
                                                        <div className={s.pending}>Pending</div>}
                                                </div>
                                            </div>
                                        </div>

                                        {
                                            item.status === 'Rejected'
                                            && <div className={classnames(s.row_value, s.flex, s.gap)}>
                                                <div className={classnames(s.flex, s.action)}
                                                     onClick={() => props.setCurrent(2)}>
                                                    <div className={s.edit_active}></div>
                                                    <div className={s.edit_text_active}>Edit</div>
                                                </div>
                                                <div className={classnames(s.flex, s.action)}
                                                     onClick={() => deleteKyc(item.id)}>
                                                    <div className={s.delete_active}></div>
                                                    <div className={s.delete_text_active}>Delete</div>
                                                </div>
                                            </div>
                                        }

                                        {
                                            item.status === 'Confirmed'
                                            && <div className={classnames(s.row_value, s.flex, s.gap)}>
                                                <div className={classnames(s.flex, s.action)}>
                                                    <div className={s.edit}></div>
                                                    <div className={s.edit_text_inactive}>Edit</div>
                                                </div>
                                                <div className={classnames(s.flex, s.action)}>
                                                    <div className={s.delete}></div>
                                                    <div className={s.delete_text_inactive}>Delete</div>
                                                </div>
                                            </div>
                                        }

                                        {
                                            item.status === 'Pending'
                                            && <div className={classnames(s.row_value, s.flex, s.gap)}>
                                                <div className={classnames(s.flex, s.action)}>
                                                    <div className={s.edit}></div>
                                                    <div className={s.edit_text_inactive}>Edit</div>
                                                </div>
                                                <div className={classnames(s.flex, s.action)}
                                                     onClick={() => deleteKyc(item.id)}>
                                                    <div className={s.delete_active}></div>
                                                    <div className={s.delete_text_active}>Delete</div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                )
                            })
                        }

                    </div>
                </div>
            }

            {isShowSumSub && (
                <div id="sumsub-container" style={{height: '100vh', width: '100%', top: 0, left: 0, position: "fixed", background: '#ffffff', zIndex: 120}}>
                    <div className="sumsub-area"></div>
                    {/* Add a close button */}
                    <button
                        style={{
                            position: "absolute",
                            top: "10px",
                            right: "10px",
                            zIndex: 121,
                            background: "#ff4d4f",
                            color: "#fff",
                            border: "none",
                            padding: "10px 20px",
                            cursor: "pointer",
                            borderRadius: "5px",
                        }}
                        onClick={() => setIsShowSumSub(false)}
                    >
                        Close
                    </button>
                </div>
            )}

        </motion.div>
    );
};

export default KycBusinessStepOne;
